import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AxiosUrl}api_mes/pellet-ctr/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData2(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AxiosUrl}api_mes/scrapim-ctr/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData3(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AxiosUrl}api_mes/fitting-ctr/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData4(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AxiosUrl}api_mes/compound-ctr/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData5(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AxiosUrl}api_mes/additive-ctr/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataEdit(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/pellet-ctr/editview`, { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/pellet-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudDataScrap(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/scrapim-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudDataFitting(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/fitting-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudDataCompound(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/compound-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudDataAdditive(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/additive-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
