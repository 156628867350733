<template>
  <b-sidebar
    id="add-compound-user-sidebar"
    :visible="isAddCompoundUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-compound-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userDataCompound.action }} Compound
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Resep -->
          <validation-provider
            #default="validationContext"
            name="Resep"
            rules="required"
          >
            <b-form-group
              label="Resep"
              label-for="resep"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userDataCompound.id_resep"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="resepOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="resep"
                :disabled="userDataCompound.action === 'Update'"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="tanggal"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="userDataCompound.tanggal"
                class="form-control"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="tanggal"
                trim
                :disabled="userDataCompound.action === 'Update'"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Nilai"
            rules="required||positive"
            type="number"
          >
            <b-form-group
              label="Nilai"
              label-for="nilai"
            >
              <b-form-input
                id="nilai"
                v-model="userDataCompound.nilai"
                type="number"
                step="0.01"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-add-compound-user-sidebar-active', false),$emit('refetch-data')"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, ref, onMounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import axios from '@axios'
import flatPickr from 'vue-flatpickr-component'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddCompoundUserSidebarActive',
    event: 'update:is-add-compound-user-sidebar-active',
  },
  props: {
    isAddCompoundUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    userDataCompound: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      value: '',
      formatted: '',
      selected: '',
    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const resepOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/resep-ctr/view`)
      const DataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ label: DataAPI[i].nama, value: DataAPI[i].id_resep })
      }
      resepOptions.value = combo
    })

    const { userDataCompound } = toRefs(props)

    const onSubmit = () => {
      store.dispatch('app-user/crudDataCompound', userDataCompound.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-compound-user-sidebar-active', false)
          if (response.data.message === 'Data Saved' || response.data.message === 'Data Updated') {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'success',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: 'Error Saving Data / Duplicate Data',
                variant: 'danger',
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      userDataCompound,
      resepOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-compound-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
