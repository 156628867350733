import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  const refUserListTable2 = ref(null)
  const refUserListTable3 = ref(null)
  const refUserListTable4 = ref(null)
  const refUserListTable5 = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'silo', sortable: true },
    { key: 'tanggal', sortable: true },
    { key: 'nilai_show', label: 'Nilai', sortable: true },
    { key: 'actions' },
  ]

  const tableColumns2 = [
    { key: 'tanggal', sortable: true },
    { key: 'nilai_show', label: 'Nilai', sortable: true },
    { key: 'actions' },
  ]

  const tableColumns3 = [
    { key: 'kode_item', sortable: true },
    { key: 'tanggal', sortable: true },
    { key: 'nilai_show', label: 'Nilai', sortable: true },
    { key: 'actions' },
  ]

  const tableColumns4 = [
    { key: 'tanggal', sortable: true },
    { key: 'nilai_show', label: 'Nilai', sortable: true },
    { key: 'actions' },
  ]

  const tableColumns5 = [
    { key: 'item', sortable: true },
    { key: 'tanggal', sortable: true },
    { key: 'nilai_show', label: 'Nilai', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id_silo')
  const isSortDirDesc = ref(true)

  const perPage2 = ref(10)
  const totalData2 = ref(0)
  const currentPage2 = ref(1)
  const perPageOptions2 = [5, 10, 25, 50, 100]
  const searchQuery2 = ref('')
  const sortBy2 = ref('id_resep')
  const isSortDirDesc2 = ref(true)

  const perPage3 = ref(10)
  const totalData3 = ref(0)
  const currentPage3 = ref(1)
  const perPageOptions3 = [5, 10, 25, 50, 100]
  const searchQuery3 = ref('')
  const sortBy3 = ref('id_resep')
  const isSortDirDesc3 = ref(true)

  const perPage4 = ref(10)
  const totalData4 = ref(0)
  const currentPage4 = ref(1)
  const perPageOptions4 = [5, 10, 25, 50, 100]
  const searchQuery4 = ref('')
  const sortBy4 = ref('id_resep')
  const isSortDirDesc4 = ref(true)

  const perPage5 = ref(10)
  const totalData5 = ref(0)
  const currentPage5 = ref(1)
  const perPageOptions5 = [5, 10, 25, 50, 100]
  const searchQuery5 = ref('')
  const sortBy5 = ref('id_resep')
  const isSortDirDesc5 = ref(true)

  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const dataMeta2 = computed(() => {
    const localItemsCount2 = refUserListTable2.value ? refUserListTable2.value.localItems.length : 0
    return {
      from: perPage2.value * (currentPage2.value - 1) + (localItemsCount2 ? 1 : 0),
      to: perPage2.value * (currentPage2.value - 1) + localItemsCount2,
      of: totalData2.value,
    }
  })

  const dataMeta3 = computed(() => {
    const localItemsCount3 = refUserListTable3.value ? refUserListTable3.value.localItems.length : 0
    return {
      from: perPage3.value * (currentPage3.value - 1) + (localItemsCount3 ? 1 : 0),
      to: perPage3.value * (currentPage3.value - 1) + localItemsCount3,
      of: totalData3.value,
    }
  })

  const dataMeta4 = computed(() => {
    const localItemsCount4 = refUserListTable4.value ? refUserListTable4.value.localItems.length : 0
    return {
      from: perPage3.value * (currentPage4.value - 1) + (localItemsCount4 ? 1 : 0),
      to: perPage4.value * (currentPage4.value - 1) + localItemsCount4,
      of: totalData4.value,
    }
  })

  const dataMeta5 = computed(() => {
    const localItemsCount5 = refUserListTable5.value ? refUserListTable5.value.localItems.length : 0
    return {
      from: perPage3.value * (currentPage5.value - 1) + (localItemsCount5 ? 1 : 0),
      to: perPage5.value * (currentPage5.value - 1) + localItemsCount5,
      of: totalData5.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
    refUserListTable2.value.refresh()
    refUserListTable3.value.refresh()
    refUserListTable4.value.refresh()
    refUserListTable5.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, planFilter], () => {
    refetchData()
  })

  watch([currentPage2, perPage2, searchQuery2, roleFilter, statusFilter, planFilter], () => {
    refetchData()
  })

  watch([currentPage3, perPage3, searchQuery3, roleFilter, statusFilter, planFilter], () => {
    refetchData()
  })

  watch([currentPage4, perPage4, searchQuery4, roleFilter, statusFilter, planFilter], () => {
    refetchData()
  })

  watch([currentPage5, perPage5, searchQuery5, roleFilter, statusFilter, planFilter], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('app-user/fetchData', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_resep: roleFilter.value,
        tanggal_from: statusFilter.value,
        tanggal_to: planFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        callback(response.data.data)
        totalData.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchData2 = (ctx, callback) => {
    store
      .dispatch('app-user/fetchData2', {
        q: searchQuery2.value,
        perPage: perPage2.value,
        page: currentPage2.value,
        sortBy: sortBy2.value,
        sortDesc: isSortDirDesc2.value,
        id_resep: roleFilter.value,
        tanggal_from: statusFilter.value,
        tanggal_to: planFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        callback(response.data.data)
        totalData2.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchData3 = (ctx, callback) => {
    store
      .dispatch('app-user/fetchData3', {
        q: searchQuery3.value,
        perPage: perPage3.value,
        page: currentPage3.value,
        sortBy: sortBy3.value,
        sortDesc: isSortDirDesc3.value,
        id_resep: roleFilter.value,
        tanggal_from: statusFilter.value,
        tanggal_to: planFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        callback(response.data.data)
        totalData3.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchData4 = (ctx, callback) => {
    store
      .dispatch('app-user/fetchData4', {
        q: searchQuery4.value,
        perPage: perPage4.value,
        page: currentPage4.value,
        sortBy: sortBy4.value,
        sortDesc: isSortDirDesc4.value,
        id_resep: roleFilter.value,
        tanggal_from: statusFilter.value,
        tanggal_to: planFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        callback(response.data.data)
        totalData4.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchData5 = (ctx, callback) => {
    store
      .dispatch('app-user/fetchData5', {
        q: searchQuery5.value,
        perPage: perPage5.value,
        page: currentPage5.value,
        sortBy: sortBy5.value,
        sortDesc: isSortDirDesc5.value,
        id_resep: roleFilter.value,
        tanggal_from: statusFilter.value,
        tanggal_to: planFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        callback(response.data.data)
        totalData5.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    fetchData2,
    tableColumns2,
    perPage2,
    currentPage2,
    totalData2,
    dataMeta2,
    perPageOptions2,
    searchQuery2,
    sortBy2,
    isSortDirDesc2,
    refUserListTable2,

    fetchData3,
    tableColumns3,
    perPage3,
    currentPage3,
    totalData3,
    dataMeta3,
    perPageOptions3,
    searchQuery3,
    sortBy3,
    isSortDirDesc3,
    refUserListTable3,

    fetchData4,
    tableColumns4,
    perPage4,
    currentPage4,
    totalData4,
    dataMeta4,
    perPageOptions4,
    searchQuery4,
    sortBy4,
    isSortDirDesc4,
    refUserListTable4,

    fetchData5,
    tableColumns5,
    perPage5,
    currentPage5,
    totalData5,
    dataMeta5,
    perPageOptions5,
    searchQuery5,
    sortBy5,
    isSortDirDesc5,
    refUserListTable5,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
