<template>

  <div>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :user-data="userData"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <add-scrap
      :is-add-scrap-user-sidebar-active.sync="isAddScrapUserSidebarActive"
      :user-data-scrap="userDataScrap"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <add-fitting
      :is-add-fitting-user-sidebar-active.sync="isAddFittingUserSidebarActive"
      :user-data-fitting="userDataFitting"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <add-compound
      :is-add-compound-user-sidebar-active.sync="isAddCompoundUserSidebarActive"
      :user-data-compound="userDataCompound"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <add-additive
      :is-add-additive-user-sidebar-active.sync="isAddAdditiveUserSidebarActive"
      :user-data-additive="userDataAdditive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
      :status-filter.sync="statusFilter"
      :plan-filter.sync="planFilter"
    />

    <!-- Table Container Card -->

    <b-tabs v-model="tabIndex">
      <b-tab title="Pellet">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="AddData()"
                  >
                    <span class="text-nowrap">Add Pellet</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="fetchData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            striped
          >
            <!-- Column: Actions -->
            <!-- <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="EditData(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="DeletePellet(data.item.id_silo, data.item.tanggal, data.item.id_resep)"
                  >Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template> -->

            <template #cell(actions)="data">
              <div
                class="text-nowrap"
              >
                <div style="line-height:80%;">
                  <br>
                </div>
                <feather-icon
                  :id="`invoice-row-${data.item.id_silo}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  :class="`text-primary`"
                  size="17"
                  @click="EditData(data.item)"
                />
                <b-tooltip
                  :title="`Edit ${data.item.silo}`"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.id_silo}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                />
&nbsp;
                <feather-icon
                  :id="`invoice-row2-${data.item.id_silo}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  :class="`text-danger`"
                  size="17"
                  @click="DeletePellet(data.item.id_silo, data.item.tanggal, data.item.id_resep)"
                />
                <b-tooltip
                  :title="`Delete ${data.item.silo}`"
                  class="cursor-pointer"
                  :target="`invoice-row2-${data.item.id_silo}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                />
              </div>
              <div style="line-height:80%;">
                <br>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- untuk tab scrap im -->
      <b-tab title="Scrap IM">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions2"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery2"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addDataScrap()"
                  >
                    <span class="text-nowrap">Add Scrap IM</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refUserListTable2"
            class="position-relative"
            :items="fetchData2"
            responsive
            :fields="tableColumns2"
            primary-key="id"
            :sort-by.sync="sortBy2"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc2"
            striped
          >

            <!-- Column: Actions -->
            <!-- <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="EditDataScrap(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="DeleteScrap(data.item.tanggal, data.item.id_resep)"
                  >Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template> -->

            <template #cell(actions)="data">
              <div
                class="text-nowrap"
              >
                <div style="line-height:80%;">
                  <br>
                </div>
                <feather-icon
                  :id="`invoice-row-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  :class="`text-primary`"
                  size="17"
                  @click="EditDataScrap(data.item)"
                />
                <b-tooltip
                  :title="`Edit Scrap IM`"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                />
&nbsp;
                <feather-icon
                  :id="`invoice-row2-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  :class="`text-danger`"
                  size="17"
                  @click="DeleteScrap(data.item.tanggal, data.item.id_resep)"
                />
                <b-tooltip
                  :title="`Delete Scrap IM`"
                  class="cursor-pointer"
                  :target="`invoice-row2-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                />
              </div>
              <div style="line-height:80%;">
                <br>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta2.from }} to {{ dataMeta2.to }} of {{ dataMeta2.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- tab Fitting -->
      <b-tab title="Fitting">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage3"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions3"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery3"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addDataFitting()"
                  >
                    <span class="text-nowrap">Add Fitting</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refUserListTable3"
            class="position-relative"
            :items="fetchData3"
            responsive
            :fields="tableColumns3"
            primary-key="id"
            :sort-by.sync="sortBy3"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc3"
            striped
          >

            <!-- Column: Actions -->
            <!-- <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="EditDataFitting(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="DeleteFitting(data.item.kode_item ,data.item.tanggal, data.item.id_resep)"
                  >Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template> -->

            <template #cell(actions)="data">
              <div
                class="text-nowrap"
              >
                <div style="line-height:80%;">
                  <br>
                </div>
                <feather-icon
                  :id="`invoice-row-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  :class="`text-primary`"
                  size="17"
                  @click="EditDataFitting(data.item)"
                />
                <b-tooltip
                  :title="`Edit ${data.item.kode_item}`"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                />
&nbsp;
                <feather-icon
                  :id="`invoice-row2-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  :class="`text-danger`"
                  size="17"
                  @click="DeleteFitting(data.item.kode_item ,data.item.tanggal, data.item.id_resep)"
                />
                <b-tooltip
                  :title="`Delete ${data.item.kode_item}`"
                  class="cursor-pointer"
                  :target="`invoice-row2-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                />
              </div>
              <div style="line-height:80%;">
                <br>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta3.from }} to {{ dataMeta3.to }} of {{ dataMeta3.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- tab comp Fitting -->
      <b-tab title="Compound Fitting">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage3"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions3"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery4"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addDataCompound()"
                  >
                    <span class="text-nowrap">Add Compound</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refUserListTable4"
            class="position-relative"
            :items="fetchData4"
            responsive
            :fields="tableColumns4"
            primary-key="id"
            :sort-by.sync="sortBy4"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc4"
            striped
          >

            <!-- Column: Actions -->
            <!-- <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="EditDataCompound(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="DeleteCompound(data.item.tanggal, data.item.id_resep)"
                  >Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template> -->

            <template #cell(actions)="data">
              <div
                class="text-nowrap"
              >
                <div style="line-height:80%;">
                  <br>
                </div>
                <feather-icon
                  :id="`invoice-row-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  :class="`text-primary`"
                  size="17"
                  @click="EditDataCompound(data.item)"
                />
                <b-tooltip
                  :title="`Edit Comp FIting`"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                />
&nbsp;
                <feather-icon
                  :id="`invoice-row2-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  :class="`text-danger`"
                  size="17"
                  @click="DeleteCompound(data.item.tanggal, data.item.id_resep)"
                />
                <b-tooltip
                  :title="`Delete Comp Fitting`"
                  class="cursor-pointer"
                  :target="`invoice-row2-${data.item.tanggal}-send-icon-${data.item.id_resep}`"
                />
              </div>
              <div style="line-height:80%;">
                <br>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta4.from }} to {{ dataMeta4.to }} of {{ dataMeta4.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- tab Additive -->
      <b-tab title="Additive">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage5"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions5"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery5"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addDataAdditive()"
                  >
                    <span class="text-nowrap">Add Additive</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refUserListTable5"
            class="position-relative"
            :items="fetchData5"
            responsive
            :fields="tableColumns5"
            primary-key="id"
            :sort-by.sync="sortBy5"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc5"
            striped
          >

            <!-- Column: Actions -->
            <!-- <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="EditDataAdditive(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="DeleteAdditive(data.item.kode_item ,data.item.tanggal, data.item.id_resep)"
                  >Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template> -->

            <template #cell(actions)="data">
              <div
                class="text-nowrap"
              >
                <div style="line-height:80%;">
                  <br>
                </div>
                <feather-icon
                  :id="`invoice-row-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  :class="`text-primary`"
                  size="17"
                  @click="EditDataAdditive(data.item)"
                />
                <b-tooltip
                  :title="`Edit ${data.item.item}`"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                />
&nbsp;
                <feather-icon
                  :id="`invoice-row2-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  :class="`text-danger`"
                  size="17"
                  @click="DeleteAdditive(data.item.kode_item ,data.item.tanggal, data.item.id_resep)"
                />
                <b-tooltip
                  :title="`Delete ${data.item.item}`"
                  class="cursor-pointer"
                  :target="`invoice-row2-${data.item.kode_item}-send-icon-${data.item.tanggal}-x-${data.item.id_resep}`"
                />
              </div>
              <div style="line-height:80%;">
                <br>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta5.from }} to {{ dataMeta5.to }} of {{ dataMeta5.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>

    <!-- Control buttons-->
    <div class="text-center">
      <b-button-group class="mt-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex--"
        >
          Previous
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex++"
        >
          Next
        </b-button>
      </b-button-group>

      <b-card-text>
        <!-- <small>Current Tab: {{ tabIndex }}</small> -->
      </b-card-text>
    </div>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BButtonGroup, BTab, BTabs, BTooltip, // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ListFilters from './ListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'
import AddScrap from './AddScrap.vue'
import AddFitting from './AddFitting.vue'
import AddCompound from './AddCompound.vue'
import AddAdditive from './AddAdditive.vue'

export default {
  components: {
    ListFilters,
    AddNew,
    AddScrap,
    AddFitting,
    AddCompound,
    AddAdditive,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BButtonGroup,
    BTab,
    BTabs,
    BTooltip,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    tabIndex: 0,
  }),
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      id_silo: '',
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: '',
      id_resep: '',
      action: 'Add',
    }

    const blankUserDataScrap = {
      id_silo: '',
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: '',
      id_resep: '',
      action: 'Add',
    }

    const blankUserDataFitting = {
      kode_item: '',
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: '',
      action: 'Add',
      id_resep: '',
    }

    const blankUserDataCompound = {
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: '',
      action: 'Add',
      id_resep: '',
    }

    const blankUserDataAdditive = {
      kode_item: '',
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: '',
      action: 'Add',
      id_resep: '',
    }

    const isAddNewUserSidebarActive = ref(false)
    const isAddScrapUserSidebarActive = ref(false)
    const isAddFittingUserSidebarActive = ref(false)
    const isAddCompoundUserSidebarActive = ref(false)
    const isAddAdditiveUserSidebarActive = ref(false)

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const userDataScrap = ref(JSON.parse(JSON.stringify(blankUserDataScrap)))
    const userDataFitting = ref(JSON.parse(JSON.stringify(blankUserDataFitting)))
    const userDataCompound = ref(JSON.parse(JSON.stringify(blankUserDataCompound)))
    const userDataAdditive = ref(JSON.parse(JSON.stringify(blankUserDataAdditive)))

    const EditData = taskData => {
      userData.value = taskData
      isAddNewUserSidebarActive.value = true
    }

    const AddData = () => {
      userData.value = blankUserData
      isAddNewUserSidebarActive.value = true
    }

    const EditDataScrap = taskData => {
      userDataScrap.value = taskData
      isAddScrapUserSidebarActive.value = true
    }

    const addDataScrap = () => {
      userDataScrap.value = blankUserDataScrap
      isAddScrapUserSidebarActive.value = true
    }

    const EditDataFitting = taskData => {
      userDataFitting.value = taskData
      isAddFittingUserSidebarActive.value = true
    }

    const addDataFitting = () => {
      userDataFitting.value = blankUserDataFitting
      isAddFittingUserSidebarActive.value = true
    }

    const EditDataCompound = taskData => {
      userDataCompound.value = taskData
      isAddCompoundUserSidebarActive.value = true
    }

    const addDataCompound = () => {
      userDataCompound.value = blankUserDataCompound
      isAddCompoundUserSidebarActive.value = true
    }

    const EditDataAdditive = taskData => {
      userDataAdditive.value = taskData
      isAddAdditiveUserSidebarActive.value = true
    }

    const addDataAdditive = () => {
      userDataAdditive.value = blankUserData
      isAddAdditiveUserSidebarActive.value = true
    }

    const roleOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/resep-ctr/view`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].nama, value: dataAPI[i].id_resep })
      }
      roleOptions.value = combo
    })

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      fetchData2,
      tableColumns2,
      perPage2,
      currentPage2,
      totalData2,
      dataMeta2,
      perPageOptions2,
      searchQuery2,
      sortBy2,
      isSortDirDesc2,
      refUserListTable2,

      fetchData3,
      tableColumns3,
      perPage3,
      currentPage3,
      totalData3,
      dataMeta3,
      perPageOptions3,
      searchQuery3,
      sortBy3,
      isSortDirDesc3,
      refUserListTable3,

      fetchData4,
      tableColumns4,
      perPage4,
      currentPage4,
      totalData4,
      dataMeta4,
      perPageOptions4,
      searchQuery4,
      sortBy4,
      isSortDirDesc4,
      refUserListTable4,

      fetchData5,
      tableColumns5,
      perPage5,
      currentPage5,
      totalData5,
      dataMeta5,
      perPageOptions5,
      searchQuery5,
      sortBy5,
      isSortDirDesc5,
      refUserListTable5,

      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
      userData,
      AddData,
      EditData,

      userDataScrap,
      addDataScrap,
      EditDataScrap,

      userDataFitting,
      addDataFitting,
      EditDataFitting,

      userDataCompound,
      addDataCompound,
      EditDataCompound,

      userDataAdditive,
      addDataAdditive,
      EditDataAdditive,

      // Sidebar
      isAddNewUserSidebarActive,
      isAddScrapUserSidebarActive,
      isAddFittingUserSidebarActive,
      isAddCompoundUserSidebarActive,
      isAddAdditiveUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      fetchData2,
      tableColumns2,
      perPage2,
      currentPage2,
      totalData2,
      dataMeta2,
      perPageOptions2,
      searchQuery2,
      sortBy2,
      isSortDirDesc2,
      refUserListTable2,

      fetchData3,
      tableColumns3,
      perPage3,
      currentPage3,
      totalData3,
      dataMeta3,
      perPageOptions3,
      searchQuery3,
      sortBy3,
      isSortDirDesc3,
      refUserListTable3,

      fetchData4,
      tableColumns4,
      perPage4,
      currentPage4,
      totalData4,
      dataMeta4,
      perPageOptions4,
      searchQuery4,
      sortBy4,
      isSortDirDesc4,
      refUserListTable4,

      fetchData5,
      tableColumns5,
      perPage5,
      currentPage5,
      totalData5,
      dataMeta5,
      perPageOptions5,
      searchQuery5,
      sortBy5,
      isSortDirDesc5,
      refUserListTable5,

      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async DeletePellet(idsilo, tanggal, idresep) {
      this.boxTwo = idsilo
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('id_silo', idsilo)
            params.append('tanggal', tanggal)
            params.append('id_resep', idresep)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/pellet-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              console.log(result)
            }
          }
        })
    },

    async DeleteScrap(tanggal, idresep) {
      this.boxTwo = idresep
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('tanggal', tanggal)
            params.append('id_resep', idresep)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/scrapim-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              console.log(result)
            }
          }
        })
    },

    async DeleteFitting(kodeitem, tanggal, idresep) {
      this.boxTwo = idresep
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('tanggal', tanggal)
            params.append('id_resep', idresep)
            params.append('kode_item', kodeitem)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/fitting-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              console.log(result)
            }
          }
        })
    },

    async DeleteCompound(tanggal, idresep) {
      this.boxTwo = idresep
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('tanggal', tanggal)
            params.append('id_resep', idresep)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/compound-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              console.log(result)
            }
          }
        })
    },

    async DeleteAdditive(kodeitem, tanggal, idresep) {
      this.boxTwo = idresep
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('tanggal', tanggal)
            params.append('id_resep', idresep)
            params.append('kode_item', kodeitem)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/additive-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              console.log(result)
            }
          }
        })
    },

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
